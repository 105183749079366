import { Injectable } from "@angular/core";
import { Aggregate, loadResource, useConfig, Timeseries, Resource } from "@hildebrand/picard-javascript";
import { Subject } from "rxjs";
import { ConfigService } from "./app-config.service";
import { GlowService } from "./glow.service";
import { StatsService } from "./stats-service";
import { environment } from '../../environments/environment';
import { ResourceTypeConfigService } from "./configuration.service";
import { DateFormatService } from "./date-format.service";

@Injectable({
    providedIn: 'root',
})
export class PicardService {

    private picardGroupConsumptionAnnouncer = new Subject<any>();
    private picardConsumptionDataAnnouncer = new Subject<any>();
    private picardGroupResourceAnnouncer = new Subject<any>();
    private SelectedPicardGroupTimeseriesAnnouncer = new Subject<any>();

    private picardAllGroupResourceAnnouncer = new Subject<any>();
    private picardAllGroupConsumptionAnnouncer = new Subject<any>();
    private picardAllGroupGasConsumptionAnnouncer = new Subject<any>();

    private picardMultiGroupResourceAnnouncer = new Subject<any>();
    private picardMultiGroupPerformanceAnnouncer = new Subject<any>();
    private picardMultiGroupSelectedSiteAnnouncer = new Subject<any>();

    private selectedGroupTimeseries: any;
    private allGroupResources: any;
    private multiGroupResources: any;
    private resourceArray: any = [];
    classifierToResourceTypeMap: any;
    resourceClassifierMap: any;

    // Observable string streams
    picardGroupConsumptionData$ = this.picardGroupConsumptionAnnouncer.asObservable();
    picardConsumptionData$ = this.picardConsumptionDataAnnouncer.asObservable();
    groupResourceData$ = this.picardGroupResourceAnnouncer.asObservable();
    selectedPicardGroupTimeseries$ = this.SelectedPicardGroupTimeseriesAnnouncer.asObservable();

    allGroupResourceData$ = this.picardAllGroupResourceAnnouncer.asObservable();
    allPicardGroupConsumptionData$ = this.picardAllGroupConsumptionAnnouncer.asObservable();
    allPicardGroupGasConsumptionData$ = this.picardAllGroupGasConsumptionAnnouncer.asObservable();

    multiPicardGroupResourceData$ = this.picardMultiGroupResourceAnnouncer.asObservable();
    multiPicardGroupPerformanceData$ = this.picardMultiGroupPerformanceAnnouncer.asObservable();
    multiPicardGroupSelectedSite$ = this.picardMultiGroupSelectedSiteAnnouncer.asObservable();

    constructor(
        private configService: ConfigService,
        private glowService: GlowService,
        private dateFormatService: DateFormatService,
        private resourceTypeConfigService: ResourceTypeConfigService,
        private statsService: StatsService
    ) {
        this.resourceTypeConfigService.getClassifierToResourceTypeMap().then(resp => {
            this.classifierToResourceTypeMap = resp;
        });
        this.resourceClassifierMap = this.resourceTypeConfigService.loadResourceClassiffierMappings()
    }

    // rollIn - single value for all group (aggregare across members)
    // rollUp - aggregate in time

    // ********* Start Individual Resource ********* //

    async getResourceTimeseries(resource, aggregate) {

        const refreshedResources = await this.preCheckResourceExpiry([resource]);
        if (refreshedResources && refreshedResources.length > 0) {

            const validResources = []
            const invalidResources = []
            refreshedResources.map(resource => { resource.timestamps.length > 0 ? validResources.push(resource) : invalidResources.push(resource) });

            let group = Timeseries.combine(...validResources.map((res) => res))

            const dataInTimeWindow = group.window({
                clip: true
            });

            let aggCount;
            let localDate;
            switch (aggregate) {
                case 'day':
                    aggCount = { days: 1 };
                    localDate = new Date(this.dateFormatService.apiTimeFromFormat(new Date(group.firstReadingTime)))
                    break;
                case 'month':
                    aggCount = { months: 1 };
                    localDate = new Date(this.dateFormatService.getStartOfMonth(new Date(group.firstReadingTime)))
                    break;
                case 'year':
                    aggCount = { years: 1 };
                    localDate = new Date(this.dateFormatService.getStartOfYear(new Date(group.firstReadingTime)))
                    break;
                default:
                    aggCount = { days: 1 };
                    localDate = new Date(this.dateFormatService.apiTimeFromFormat(new Date(group.firstReadingTime)))
            }

            let units = (this.resourceClassifierMap.dataStreamType.gas.find(classifierElem => classifierElem === resource.classifier)) ? 'Wh' : 'kWh';

            const consumptionData = []
            validResources.map(resourceElem => {
                let label, veId, resp;
                for (const [key, value] of resourceElem.columns.entries()) {
                    label = value.attributes.label;
                    veId = value.attributes.data.veId
                }

                let data;
                let startDate;

                if (aggregate == 'halfHourly') {
                    data = dataInTimeWindow.points(resourceElem.resourceId)
                } else {
                    startDate = localDate.getTime() - localDate.getTimezoneOffset() * 60000;
                    const rolledUpTs = dataInTimeWindow.rollUp({ aggregate: Aggregate.SUM, from: startDate, to: group.lastReadingTime, duration: aggCount });
                    data = rolledUpTs.points(resourceElem.resourceId)
                }

                consumptionData.push({ veId, resourceId: resourceElem.resourceId, units, label, firstReadingTime: group.firstReadingTime, lastReadingTime: resourceElem.lastReadingTime, data })
            })

            invalidResources.map(resourceElem => {
                let label, veId, units;
                for (const [key, value] of resourceElem.columns.entries()) {
                    label = value.attributes.label;
                    veId = value.attributes.data.veId
                }
                consumptionData.push({ veId, units, resourceId: resourceElem.resourceId, label, firstReadingTime: group.firstReadingTime, lastReadingTime: resourceElem.lastReadingTime, data: [] })
            });

            this.announceConsumptionData(consumptionData)
        }
    }

    // ********* End Individual Resource ********* //

    // ********* Start Individual Group ********* //

    async getGroupTimeseries(resources, from, to, period) {

        const refreshedResources = await this.preCheckResourceExpiry(resources);
        if (refreshedResources && refreshedResources.length > 0) {

            let dayCount;
            switch (period) {
                case 'day':
                    dayCount = 1;
                    break;
                case 'lastSevenDays':
                case 'week':
                    dayCount = 7;
                    break;
                case 'month':
                    const date = new Date(from);
                    const currentYear = date.getFullYear();
                    const currentMonth = date.getMonth() + 1;
                    dayCount = new Date(currentYear, currentMonth, 0).getDate();
                    break;
                case 'year':
                    dayCount = 365;
                    break;
                default:
                    dayCount = 1;
            }

            const validResources = []
            const invalidResources = []
            refreshedResources.map(resource => { resource.timestamps.length > 0 ? validResources.push(resource) : invalidResources.push(resource) });

            let group;
            if (!this.selectedGroupTimeseries) {
                group = Timeseries.combine(...validResources.map((res) => res))
                this.announceGroupTimeseries(group)
            } else {
                group = this.selectedGroupTimeseries;
            }

            const dataInTimeWindow = group.window({
                from: new Date(from).getTime(),
                to: new Date(to).getTime(),
                clip: true
            });

            const rolledUpTs = dataInTimeWindow.rollUp({ aggregate: Aggregate.SUM, from: new Date(from).getTime(), to: new Date(to).getTime(), duration: { days: dayCount } });

            const consumptionData = []
            validResources.map(resource => {

                let label, veId, resp;
                let units = (this.classifierToResourceTypeMap.dataStreamType.gas.find(classifierElem => classifierElem === resource.classifier)) ? 'Wh' : 'kWh';

                for (const [key, value] of resource.columns.entries()) {
                    label = value.attributes.label;
                    // propertyCode = value.attributes.data.attributes.propertyInfo.propertyCode;
                    veId = value.attributes.data.veId
                }
                resp = rolledUpTs.points(resource.resourceId);
                resp[0][1] = resp[0][1] > 0 ? resp[0][1] / 1000 : 0;
                consumptionData.push({ veId, resourceId: resource.resourceId, units, label, lastReadingTime: resource.lastReadingTime, data: resp })
            })

            invalidResources.map(resource => {
                let label, veId;
                let units = (this.classifierToResourceTypeMap.dataStreamType.gas.find(classifierElem => classifierElem === resource.classifier)) ? 'Wh' : 'kWh';
                for (const [key, value] of resource.columns.entries()) {
                    label = value.attributes.label;
                    // propertyCode = value.attributes.data.attributes.propertyInfo.propertyCode;
                    veId = value.attributes.data.veId
                }
                consumptionData.push({ veId, units, resourceId: resource.resourceId, label, lastReadingTime: resource.lastReadingTime, data: [] })
            });

            this.announceConsumptionData(consumptionData)
        }
    }

    async getPicardGroupResource(arrayOfResources) {
        const resources: Resource[] = [];
        if (arrayOfResources) {
            const lastResponse = await arrayOfResources.reduce((accumulatorPromise, resourceIdElem) => {
                return accumulatorPromise.then(async response => {
                    if (response) resources.push(response)
                    return this.getResource(resourceIdElem)
                })
            }, Promise.resolve());
            if (lastResponse) resources.push(lastResponse);
        }
        this.announceGroupResource(resources)
    }

    async getGroupConsumption(queries) {
        if (this.selectedGroupTimeseries) {
            const groupResponse = [];
            const lastResponse = await queries.reduce((accumulatorPromise, query) => {
                return accumulatorPromise.then(async response => {
                    if (response) groupResponse.push(response)
                    return this.rollInGroupTimeseries(this.selectedGroupTimeseries, query)
                })
            }, Promise.resolve());
            if (lastResponse) groupResponse.push(lastResponse);
            this.announceGroupConsumption(groupResponse)
        }
    }

    rollInGroupTimeseries(group, query) {
        const dataInTimeWindow = group.window({
            from: new Date(query.from).getTime(),
            to: new Date(query.to).getTime(),
            clip: true
        });
        const rolledInTs = dataInTimeWindow.rollIn({ aggregate: Aggregate.SUM });
        return { ...query, total: rolledInTs.sum() / 1000 }
    }

    async addResourceToGroup(groupVeId, addedResources) { // frontend allows adding multiple
        if (this.allGroupResources) {
            const filteredResources = this.allGroupResources.find(x => x.veId === groupVeId)
            const lastResponse = await addedResources.reduce((accumulatorPromise, resourceIdElem) => {
                return accumulatorPromise.then(async response => {
                    if (response) filteredResources.resources.push(response)
                    return this.getResource(resourceIdElem)
                })
            }, Promise.resolve());
            if (lastResponse) filteredResources.resources.push(lastResponse);
        }
    }

    addGroup({ veId, name }) {
        if (this.allGroupResources) {
            this.allGroupResources.push({ veId, name, resources: [] })
        }
    }

    removeResourceFromGroup(groupVeId, removedResource) { // frontend prevents removing multiple
        if (this.allGroupResources) {
            const filteredResources = this.allGroupResources.find(resource => resource.veId === groupVeId)
            for (var i = 0; i < filteredResources.resources.length; i++) {
                var obj = filteredResources.resources[i];
                if (removedResource[0].resourceId == obj.resourceId) {
                    filteredResources.resources.splice(i, 1);
                }
            }
        }
    }

    removeGroup(veId) {
        // console.log('removeGroup', veId, this.allGroupResources)
        for (var i = 0; i < this.allGroupResources.length; i++) {
            var obj = this.allGroupResources[i];
            if (veId == obj.veId) {
                this.allGroupResources.splice(i, 1);
            }
        }
    }

    // ********* End Individual Group ********* //



    // ********* Start All Group ********* //

    getStoredAllGroupResource() {
        return this.allGroupResources;
    }

    clearAllGroupResource() {
        // console.log("clearAllGroupResource")
        this.resourceArray = [];
        this.allGroupResources = [];
        this.multiGroupResources = [];
        this.selectedGroupTimeseries = []
    }

    async getAllGroupResource(virtualEntities) {
        const resources: Resource[] = [];
        if (virtualEntities) {
            const lastResponse = await virtualEntities.reduce((accumulatorPromise, resourceIdElem) => {
                return accumulatorPromise.then(async response => {
                    if (response) resources.push(response)
                    return this.getPicardAllGroupResources(resourceIdElem)
                })
            }, Promise.resolve());
            if (lastResponse) resources.push(lastResponse);
            this.announceAllGroupResources(resources)
            return resources;
        }
    }

    async getPicardAllGroupResources({ veId, name, resourceArray }) {
        const resources: Resource[] = [];
        if (resourceArray) {
            const lastResponse = await resourceArray.reduce((accumulatorPromise, resourceIdElem) => {
                return accumulatorPromise.then(async response => {
                    if (response) resources.push(response)
                    return this.getResource(resourceIdElem)
                })
            }, Promise.resolve());
            if (lastResponse) resources.push(lastResponse);
        }
        return { veId, name, resources: resources }
    }

    // async getAllGroupTimeseriesCosumption(resources, from, to, dataStreamType) {

    //     const refreshedResources = await this.preCheckResourceExpiry(resources);
    //     if (refreshedResources && refreshedResources.length > 0) {

    //         const groupResponse = [];
    //         const lastResponse = await refreshedResources.reduce((accumulatorPromise, resource) => {
    //             return accumulatorPromise.then(async response => {
    //                 if (response) groupResponse.push(response)
    //                 return this.getGroupTimeseriesCosumption({ ...resource, from, to, dataStreamType })
    //             })
    //         }, Promise.resolve());
    //         if (lastResponse) groupResponse.push(lastResponse);

    //         if (dataStreamType == 'gas.consumption') {
    //             this.announceAllGroupGasConsumption(groupResponse)
    //         } else {
    //             this.announceAllGroupConsumption(groupResponse)
    //         }
    //     }
    // }

    // async getGroupTimeseriesCosumption({ veId, name, resources, from, to, dataStreamType }) {

    //     const refreshedResources = await this.preCheckResourceExpiry(resources);
    //     if (refreshedResources && refreshedResources.length > 0) {
    //         const validResources = []
    //         const invalidResources = []

    //         refreshedResources.map(resource => { resource.timestamps.length > 0 && this.classifierToResourceTypeMap[dataStreamType].indexOf(resource.resourceTypeId) > -1 ? validResources.push(resource) : invalidResources.push(resource) });

    //         const uniqueSite = []
    //         refreshedResources.map(x => {
    //             let label;
    //             for (const [key, value] of x.columns.entries()) {
    //                 label = value.attributes.label;
    //                 if (!uniqueSite.includes(label)) {
    //                     uniqueSite.push(label)
    //                 }
    //             }
    //         })

    //         if (validResources && validResources.length > 0) {

    //             const group = Timeseries.combine(...validResources.map((res) => res))
    //             const dataInTimeWindow = group.window({
    //                 from: new Date(from).getTime(),
    //                 to: new Date(to).getTime(),
    //                 clip: true
    //             });
    //             const rolledInTs = dataInTimeWindow.rollIn({ aggregate: Aggregate.SUM });

    //             return {
    //                 veId, groupName: name,
    //                 gasTotal: dataStreamType == 'gas.consumption' ? rolledInTs.sum() / 1000 : 0,
    //                 elecTotal: dataStreamType == 'electricity.consumption' ? rolledInTs.sum() / 1000 : 0,
    //                 groupSize: uniqueSite.length
    //             }
    //         }

    //         return {
    //             veId, groupName: name,
    //             gasTotal: 0,
    //             elecTotal: 0,
    //             groupSize: uniqueSite.length
    //         }
    //     }
    // }

    // ********* End All Group ********* //



    // ********* Start Multi Group (Group performance) ********* //

    async getMultiGroupResource(virtualEntities) {
        const resources: Resource[] = [];
        if (virtualEntities) {
            const lastResponse = await virtualEntities.reduce((accumulatorPromise, resourceIdElem) => {
                return accumulatorPromise.then(async response => {
                    if (response) resources.push(response)
                    return this.getPicardAllGroupResources(resourceIdElem)
                })
            }, Promise.resolve());
            if (lastResponse) resources.push(lastResponse);
            this.announceMultiGroupResources(resources)
        }
    }

    async getMultiGroupTimeseriesPerformance(veId, resources, from, to, period) {
        const groupResponse = [];
        const lastResponse = await resources.reduce((accumulatorPromise, resource) => {
            return accumulatorPromise.then(async response => {
                if (response) groupResponse.push(response)
                return this.getGroupTimeseriesPerformance({ ...resource, from, to, period, selectedVeId: veId })
            })
        }, Promise.resolve());
        if (lastResponse) groupResponse.push(lastResponse);
        this.announceMultiGroupPerformance(groupResponse)
    }

    async getGroupTimeseriesPerformance({ selectedVeId, veId, name, resources, from, to, period }) {

        const refreshedResources = await this.preCheckResourceExpiry(resources);
        if (refreshedResources && refreshedResources.length > 0) {


            const validResources = []
            const invalidResources = []
            refreshedResources.map(resource => { resource.timestamps.length > 0 ? validResources.push(resource) : invalidResources.push(resource) });

            const group = Timeseries.combine(...validResources.map((res) => res))
            const dataInTimeWindow = group.window({
                from: new Date(from).getTime(),
                to: new Date(to).getTime(),
                clip: true
            });

            let dayCount;
            switch (period) {
                case 'day':
                    dayCount = 1;
                    break;
                case 'week':
                    dayCount = 7;
                    break;
                case 'month':
                    const date = new Date(from);
                    const currentYear = date.getFullYear();
                    const currentMonth = date.getMonth() + 1;
                    dayCount = new Date(currentYear, currentMonth, 0).getDate();
                    break;
                case 'year':
                    dayCount = 365;
                    break;
                default:
                    dayCount = 1;
            }

            const rolledUpTs = dataInTimeWindow.rollUp({ aggregate: Aggregate.SUM, from: new Date(from).getTime(), to: new Date(to).getTime(), duration: { days: dayCount } });

            const consumptionData = []
            validResources.map(resource => {

                let label, veId, resp;
                for (const [key, value] of resource.columns.entries()) {
                    label = value.attributes.label;
                    veId = value.attributes.data.veId
                }
                resp = rolledUpTs.points(resource.resourceId);
                resp[0][1] = resp[0][1] > 0 ? resp[0][1] / 1000 : 0;
                consumptionData.push([label, resp[0][1]])

                if (selectedVeId == veId) {
                    this.announceMultiGroupSelectedSite({ name: label, value: resp[0][1] })
                }
            });

            invalidResources.map(resource => {
                let label, propertyCode, veId;
                for (const [key, value] of resource.columns.entries()) {
                    label = value.attributes.label;
                    propertyCode = value.attributes.data.attributes.propertyInfo.propertyCode;
                    veId = value.attributes.data.veId
                }
                consumptionData.push([label, 0])
            });

            const sortedValues = consumptionData.sort((a, b) => a[1] - b[1])
            const averageValues = sortedValues.filter(val => val[1] !== 0);

            let stat = {
                lowerRange: {
                    shouldDisplay: true,
                    value: sortedValues[0][1]
                },
                lowerQuartile: {
                    shouldDisplay: false,
                    value: 0
                },
                median: {
                    shouldDisplay: false,
                    value: 0
                },
                average: {
                    shouldDisplay: true,
                    value: this.statsService.calculateArrayAverage(averageValues)
                },
                mean: {
                    shouldDisplay: true,
                    value: this.statsService.calculateArrayAverage(sortedValues)
                },
                upperQuartile: {
                    shouldDisplay: false,
                    value: 0
                },
                upperRange: {
                    shouldDisplay: true,
                    value: sortedValues[sortedValues.length - 1][1]
                }
            }

            if (consumptionData.length >= 3) {
                stat = {
                    ...stat,
                    median: {
                        shouldDisplay: true,
                        value: this.statsService.calculateQuartile(sortedValues, 0.5),
                    },
                }
            }

            if (consumptionData.length >= 5) {
                stat = {
                    ...stat,
                    lowerQuartile: {
                        shouldDisplay: true,
                        value: this.statsService.calculateQuartile(sortedValues, 0.25),
                    },
                    upperQuartile: {
                        shouldDisplay: true,
                        value: this.statsService.calculateQuartile(sortedValues, 0.75)

                    }
                }
            }

            return { veId, groupName: name, stat };
        }
    }

    getStoredMultiGroupResource() {
        return this.multiGroupResources;
    }
    // ********* End Multi Group (Group performance) ********* //



    // ********* Start global methods ********* //

    async getResource({ resourceId, resourceTypeId, attributes }) {

        const apiConfig = this.configService.loadConfig();
        const headers = this.glowService.getAuthHeaders()
        // if we already have resource, don't retrieve again
        const existingResource = this.resourceArray.find(x => x.resourceId === resourceId);
        if (existingResource) {
            return existingResource;
        } else {
            useConfig({ baseURL: environment.baseUrl, applicationId: apiConfig.application.applicationId, token: headers.get('token') });
            let resource;
            try {
                resource = await loadResource(resourceId)
                resource.series().id = resource.resourceId;
                resource.series().attributes = attributes;
                resource['resourceTypeId'] = resourceTypeId;
                this.resourceArray.push(resource)
            } catch (e) {
                console.warn(`Error retrieving resource ${resourceId} | attributes veId ${attributes && attributes.veId} | Label ${attributes && attributes.label}`)
                console.warn(e)
            }

            return resource;
        }
    }

    async preCheckResourceExpiry(resources) {
        // console.log('preCheckResourceExpiry', resources)

        if (resources) {
            const expiryTimestamp = new Date().getTime() - (6 * 60 * 60 * 1000); // 6 hours 
            const expiredResources = [];
            const stillValidResources = [];

            resources.map(x => {
                if (x.retrievalTs < expiryTimestamp) {
                    let attributes;
                    for (const [key, value] of x.columns.entries()) {
                        attributes = value.attributes;
                    }
                    expiredResources.push({ resourceId: x.resourceId, resourceTypeId: x.resourceTypeId, attributes, expiredBy: (x.retrievalTs - expiryTimestamp) })
                } else {
                    stillValidResources.push(x)
                }
            });

            if (expiredResources && expiredResources.length > 0) {

                const expiredResourceIdArray = []
                expiredResources.map(x => expiredResourceIdArray.push(x.resourceId))

                // Remove expired resources from stored resources
                for (var i = 0; i < this.resourceArray.length; i++) {
                    var obj = this.resourceArray[i];
                    if (expiredResourceIdArray.indexOf(obj.resourceId) !== -1) {
                        // console.log(obj.resourceId, ' EXPIRED - REMOVED FROM resourceArray retrievalTs -', new Date(obj.retrievalTs), ' expiry-', new Date(expiryTimestamp));
                        this.resourceArray.splice(i, 1);
                    }
                }

                const refreshedResources: Resource[] = [];
                const lastResponse = await expiredResources.reduce((accumulatorPromise, resourceIdElem) => {
                    return accumulatorPromise.then(async response => {
                        if (response) refreshedResources.push(response)
                        return this.getResource(resourceIdElem)
                    })
                }, Promise.resolve());
                if (lastResponse) refreshedResources.push(lastResponse);

                return [...refreshedResources, ...stillValidResources]
            }
        }

        return resources;
    }

    // ********* End global methods ********* //



    // ********* Start Announcers ********* //

    announceConsumptionData(consumptionData: any) {
        // console.log("announcing consumption data")
        this.picardConsumptionDataAnnouncer.next(consumptionData);
    }

    announceGroupResource(resources: any) {
        // console.log("announcing group resources")
        this.picardGroupResourceAnnouncer.next(resources);
    }

    announceGroupConsumption(resources: any) {
        // console.log("announcing group consumption")
        this.picardGroupConsumptionAnnouncer.next(resources);
    }

    announceGroupTimeseries(group: any) {
        // console.log("announcing group timeseries")
        this.selectedGroupTimeseries = group;
        this.SelectedPicardGroupTimeseriesAnnouncer.next(group);
    }



    announceAllGroupResources(resources: any) {
        // console.log("announcing all group resources")
        this.allGroupResources = resources;
        this.picardAllGroupResourceAnnouncer.next(resources);
    }

    announceAllGroupConsumption(resources: any) {
        // console.log("announcing all groups consumption")
        this.picardAllGroupConsumptionAnnouncer.next(resources);
    }

    announceAllGroupGasConsumption(resources: any) {
        // console.log("announcing all groups gas consumption")
        this.picardAllGroupGasConsumptionAnnouncer.next(resources);
    }


    announceMultiGroupResources(resources: any) {
        // console.log("announcing multi group resources")
        this.multiGroupResources = resources;
        this.picardMultiGroupResourceAnnouncer.next(resources);
    }

    announceMultiGroupPerformance(resources: any) {
        // console.log("announcing multi group performance")
        this.picardMultiGroupPerformanceAnnouncer.next(resources);
    }

    announceMultiGroupSelectedSite(resources: any) {
        // console.log("announcing multi group selected site")
        this.picardMultiGroupSelectedSiteAnnouncer.next(resources);
    }

    // ********* End Announcers ********* //
}